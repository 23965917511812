<template>
    <div id="body">
        <div class="fatherBox">
            <div class="headerBox">
                <p>发送通知</p>
            </div>
            <div class="stubody" style="margin:25px 40px 10px 10px;">
                <div style="float: right;padding: 10px;margin-top: -21px;">
                    <el-button type="primary" @click="onSend">发送</el-button>
                </div>
                <div style="text-align: left;width:93%">
                    <template>
                        <div style=" margin: auto; margin-left: 80px;">
                            <el-form label-width="120px" class="demo-ruleForm">
                                <el-form-item label="通知标题">
                                    <el-input class="select" v-model.trim="noticeTitle" placeholder="清输入通知标题"
                                        style="width: 960px;">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="通知内容">
                                    <el-input class="select" v-model.trim="noticeContent" type="textarea"  :autosize="{ minRows: 15, maxRows: 20 }"  placeholder="清输入通知内容"
                                        style="width: 960px;">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="通知用户">
                                    <el-radio-group v-model="noticeTarget">
                                    <el-radio label="all">全部学生</el-radio>
                                    <el-radio label="part">部分学生</el-radio>
                                </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <div style="padding:40px;" v-if="noticeTarget=='part'">
                        <div style="font-weight: bold;margin-bottom:20px;">请选择学生</div>
                        <el-checkbox v-for="item,index in stu_list" v-model="stu_list[index].checked" :label="item.label" border :disabled="item.parentInfo.length<1"></el-checkbox>
                        
                    </div>
                </div>
            </div>
            <el-dialog :visible.sync="dialogVisible" title='导出数据' width="500px">
                <div>
                    <el-form label-width="80px" class="demo-ruleForm">
                        <el-form-item label="时间范围">
                            <el-date-picker v-model="daterange" value-format="yyyy-MM-dd" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doExport">确 定</el-button>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import Vue from 'vue'
import {
    stuEyeSearch,
    excelDaochueyeDays
} from '@/api/SchoolApi.js'
import {
    noticeList,
    sendScore,
    stuData,sendNotice
} from '@/api/TeacherApi.js'
import { Message } from 'element-ui';
export default {
    computed: {
        ...mapGetters(['Status'])
    },
    data() {
        return {
            stuUserID: '',
            StuName: '',
            dstatus: false,
            loading: false,
            cityid: '',
            stu_list: [],
            dialogVisible: false,
            daterange: [],
            noticeTitle: '',
            noticeContent: '',
            noticeTarget: 'all',
            noticeStudents: [],
        }
    },
    mounted() {

        this.StuInfo()
        console.log(this.$route.params.id)
        if (this.$route.params.id) {
            noticeList({id:this.$route.params.id}).then(res=> {
                const {title,content} = res.data.list[0]
                this.noticeTitle = title
                this.noticeContent = content
            })
        }
    },
    methods: {
        onSend() {
            if (!this.noticeTitle) {
                Message.error('请输入通知标题')
                return
            }
            if (!this.noticeContent) {
                Message.error('请输入通知内容')
                return
            }
            if (this.noticeTarget == 'part') {
                const list = this.stu_list.filter(item=>item.checked)
                if (!list.length) {
                    Message.error('请选择学生')
                    return
                }
            }

            const ids = this.noticeTarget == 'part' ? this.stu_list.filter(item=>item.checked).map(item=>item.id) : this.stu_list.map(item=>item.id)
            const str = ids.join()

            


            const data = {
                title: this.noticeTitle,
                con: this.noticeContent,
                stu_id: str
            }
            sendNotice(data).then(res=>{
                Message.success('通知发送成功')
                this.$router.push({name: 'noticeList'})
            })
        },
        StuInfo() {
            const data = {}
            stuData(data).then(res => {
                this.stu_list = res.data.map(item => {
                    return  {
                        label: item.StuName,
                        checked: false,
                        id: item.StuUserID,
                        parentInfo: item.parentInfo
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        upStuHealth() { // 更新健康信息
            this.$router.push({
                name: 'health_update',
                params: {
                    student_id: this.stuUserID                                                                                                                                                                                                                                                                                     ``                                                      `                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `````````````````````````````````````````````````````````````````````````   
                }
            })
        },
        doExport() {
            this.loading = true
            if (this.daterange.length == 0) {
                this.$message.error('请选择时间范围')
                return false
            }
            const data = {
                studentid: this.stuUserID, // 学生ID
                city: this.cityid,
                startdate: this.daterange[0],
                enddate: this.daterange[1]
            }
            excelDaochueyeDays(data).then(res => {
                this.loading = false
                if (res.status.code == 1) {
                    window.open(Vue.prototype.$url + res.data)
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.status.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

}
</script>

<style lang="scss" scoped>
//新样式
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.stutitle {
    font-size: 30px !important;
    margin-right: 55% !important;
}

.tt {
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 500;
}

.tabtit {
    width: 100px;
    display: inline-block;
    text-align: right;
}

tr {
    line-height: 45px;
}

#body {
    background: #f0f2f5;
}

.fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
        display: flex;
        height: 52px;
        width: 98%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #e9e9e9;

        p {
            font-size: 16px;
            font-family: 萍方-简;
            font-weight: normal;
            color: #000000;
            margin-left: 32px;
        }

        .btn {
            display: flex;
            align-items: center;
            width: 109px;
            height: 32px;
            opacity: 1;
            border-radius: 2px;
        }
    }

    .del {
        float: left,

    }

    .query {
        width: 98%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-left: 32px;

        .queryLeft {
            width: 75%;
            display: flex;
            flex-wrap: wrap;

            div {
                width: 300px;
                line-height: 50px;
                text-align: left;

                .input,
                .select {
                    width: 60%;
                }
            }
        }
    }
}
</style>